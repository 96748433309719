<template>

  <!-- Layout Header ( Navbar ) -->
  <a-layout-header>
    <router-link to="/">
      <div class="header-col header-brand" style="display: flex">
        <img src="images/logo-black.png" alt="" style="width: 46px">
        <h6>CopyApes 跟单猿</h6>

        <!-- Trigger Button For Navigation Menu For Small Screens -->
        <a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/>
          </svg>
        </a-button>
        <!-- Trigger Button For Navigation Menu For Small Screens -->

      </div>
    </router-link>
    <div class="header-col header-nav">

      <!-- Navigation Menu For Large Screens -->
      <a-menu mode="horizontal" class="menu-large">
        <a-menu-item>
          <router-link to="/" class="nav-link">
					<span class="submenu-title-wrapper nav-menu">
						<span>首页</span>
					</span>
          </router-link>
        </a-menu-item>
        <a-menu-item>
          <router-link to="/dashboard" class="nav-link">
					<span class="submenu-title-wrapper nav-menu">
						<span>推广计划</span>
					</span>
          </router-link>
        </a-menu-item>
        <a-menu-item>
          <a href="https://xwvmohge80.feishu.cn/wiki/WHcEwy0dQinSMIkms9LcrVr7nUF" target="_blank">
					<span class="submenu-title-wrapper nav-menu">
						<span>帮助手册</span>
					</span>
          </a>
        </a-menu-item>
        <a-menu-item>
          <a href="https://xwvmohge80.feishu.cn/wiki/WHcEwy0dQinSMIkms9LcrVr7nUF" target="_blank">
					<span class="submenu-title-wrapper nav-menu">
						<span>常见问题</span>
					</span>
          </a>
        </a-menu-item>
        <a-menu-item>
          <router-link to="/contact" class="nav-link">
					<span class="submenu-title-wrapper nav-menu">
						<span>联系我们</span>
					</span>
          </router-link>
        </a-menu-item>
      </a-menu>
      <!-- / Navigation Menu For Large Screens -->

      <!-- Collapsible Navigation Menu For Small Screens -->
      <div class="menu-small">

        <!-- Collapsible Component For Navigation Menu For Small Screens -->
        <a-collapse v-model="collapseNav" accordion>
          <a-collapse-panel key="1">

            <!-- Navigation Menu For Small Screens -->
            <a-menu mode="vertical">
              <a-menu-item>
                <router-link to="/" class="nav-link">
                  <span class="submenu-title-wrapper nav-menu">
                    <span>首页</span>
                  </span>
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <router-link to="/dashboard" class="nav-link">
                  <span class="submenu-title-wrapper nav-menu">
                    <span>推广计划</span>
                  </span>
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <a href="https://xwvmohge80.feishu.cn/wiki/WHcEwy0dQinSMIkms9LcrVr7nUF">
                  <span class="submenu-title-wrapper nav-menu">
                    <span>帮助手册</span>
                  </span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="https://xwvmohge80.feishu.cn/wiki/WHcEwy0dQinSMIkms9LcrVr7nUF">
					<span class="submenu-title-wrapper nav-menu">
						<span>常见问题</span>
					</span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <router-link to="/contact" class="nav-link">
                  <span class="submenu-title-wrapper nav-menu">
                    <span>联系我们</span>
                  </span>
                </router-link>
              </a-menu-item>
            </a-menu>
            <!-- / Navigation Menu For Small Screens -->

          </a-collapse-panel>
        </a-collapse>
        <!-- / Collapsible Component For Navigation Menu For Small Screens -->

      </div>
      <!-- / Collapsible Navigation Menu For Small Screens -->

    </div>
    <div class="header-col header-btn">
      <router-link to="/sign-in" class="nav-link">
        <a-button type="primary" ghost>
          登 录 / 注 册
        </a-button>
      </router-link>
    </div>
  </a-layout-header>
  <!-- / Layout Header ( Navbar ) -->

</template>

<script>

export default ({
  data() {
    return {
      // Collapse navigation value.
      // Binded model property for "Collapsible Navigation Menu" collapsed status .
      collapseNav: 0,
    }
  },
})

</script>

<style lang="scss" scoped>

.nav-menu {
  color: #141414 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.nav-link svg {
  margin-right: 5px;
  vertical-align: middle;
}

.nav-link span {
  vertical-align: middle;
}

.ant-menu-submenu-popup {
  width: 100%;
}

</style>